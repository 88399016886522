<script>
export default {
    data() {
        return {};
    },
    computed: {},
    created() {},
};
</script>

<template>
    <b-container class="policy">
        <h1
            style="
                padding-top: 3pt;
                padding-left: 24pt;
                text-indent: 0pt;
                text-align: center;
            "
        >
            ĐIỀU KHOẢN VÀ ĐIỀU KIỆN SỬ DỤNG DỊCH VỤ TƯ VẤN ĐẦU TƯ
        </h1>
        <h2
            style="
                padding-top: 9pt;
                padding-left: 10pt;
                text-indent: 16pt;
                line-height: 108%;
                text-align: left;
            "
        >
            ĐIỀU 1: GIẢI THÍCH MỘT SỐ TỪ NGỮ ĐƯỢC DÙNG TRONG VIỆC SỬ DỤNG LIÊN
            QUAN ĐẾN DỊCH VỤ TƯ VẤN ĐẦU TƯ
        </h2>
        <ol id="l1">
            <ol id="l2">
                <li data-list-text="1.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 28pt;
                            text-indent: -18pt;
                            text-align: justify;
                        "
                    >
                        PIF là Công ty tư vấn đầu tư và dịch vụ gọi vốn.
                    </p>
                </li>
                <li data-list-text="1.2">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 108%;
                            text-align: justify;
                        "
                    >
                        Khách hàng bao gồm các cá nhân, tổ chức, có nhu cầu sử
                        dụng các dịch vụ được cung cấp qua kênh tư vấn đầu tư
                        của PIF, là chủ tài khoản được mở tại PIF và là chủ thể
                        đứng tên trên Hợp đồng tư vấn đầu tư.
                    </p>
                </li>
                <li data-list-text="1.3">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Tư vấn đầu tư: là một kênh quản lý danh mục đầu tư tự
                        động, mà theo đó, Khách hàng sẽ đăng ký với PIF một lần
                        duy nhất để ủy quyền cho PIF tự động trích tiền từ tài
                        khoản đầu tư (áp dụng đối với dịch vụ tư vấn đầu tư theo
                        nhu cầu) của Khách hàng mở tại PIF để quản lý và thực
                        hiện tác vụ tài chính theo các tiêu chí định sẵn của
                        Khách hàng.
                    </p>
                </li>
                <li data-list-text="1.4">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Đối tượng tư vấn đầu tư/Đối tượng thanh toán: là thông
                        tin, tên đối tác, số tài khoản thụ hưởng… phản ảnh nhu
                        cầu nhận lợi tức đầu tư, được các bên ghi nhận dùng để
                        làm thông tin nhận dạng cho việc sử dụng dịch vụ tư vấn
                        đầu tư giữa Khách hàng và PIF.
                    </p>
                </li>
                <li data-list-text="1.5">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Hợp đồng: là nội dung đăng ký đầu tư được chương trình
                        ghi nhận đối với từng Đối tượng tư vấn đầu tư. Mỗi hợp
                        đồng được hệ thống quản lý bằng một số tham chiếu.
                    </p>
                </li>
                <li data-list-text="1.6">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Phiếu đăng ký sử dụng dịch vụ tư vấn đầu tư (“Phiếu đăng
                        ký”): là nội dung đăng ký sử dụng dịch vụ tư vấn đầu tư
                        do PIF cung cấp. Tùy từng thời điểm, từng trường hợp,
                        Phiếu đăng ký có thể phản ánh nội dung tư vấn đầu tư cho
                        một hoặc nhiều Đối tượng tư vấn đầu tư.
                    </p>
                </li>
                <li data-list-text="1.7">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 107%;
                            text-align: left;
                        "
                    >
                        Lịch thanh toán: là thông tin thể hiện thời điểm cần
                        thực hiện nghĩa vụ thanh toán mà Khách hàng đã đăng ký
                        trong hợp đồng tư vấn đầu tư với PIF.
                    </p>
                </li>
                <li data-list-text="1.8">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Tài khoản đầu tư: là tài khoản là tài khoản tiếp nhận
                        tiền của Khách hàng mở tại các chi nhánh Ngân hàng CPTM
                        được Khách hàng đăng ký chỉ định dùng mặc định cho việc
                        nhận tiền, chỉ tồn tại duy nhất 01 (một) một số tài
                        khoản.
                    </p>
                </li>
                <li data-list-text="1.9">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -18pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Tên gợi nhớ: là thông tin tên gọi mà khách hàng tự đặt
                        cho nội dung đăng ký tư vấn đầu tư của mình nhằm mục
                        đích dễ nhớ dễ phân biệt.
                    </p>
                </li>
                <li data-list-text="1.10">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -22pt;
                            text-align: left;
                        "
                    >
                        Ngày giao dịch: là ngày giờ ghi nhận phát sinh giao
                        dịch.
                    </p>
                </li>
                <li data-list-text="1.11">
                    <p
                        style="
                            padding-top: 1pt;
                            padding-left: 28pt;
                            text-indent: -22pt;
                            text-align: left;
                        "
                    >
                        Ngày hiệu lực: là ngày giờ giao dịch được ghi nhận hạch
                        toán (sau 72h) vào tài khoản Khách hàng.
                    </p>
                </li>
                <li data-list-text="1.12">
                    <p
                        style="
                            padding-left: 28pt;
                            text-indent: -22pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Ngày làm việc được hiểu là các ngày làm việc của PIF từ
                        Thứ Hai tới Thứ Sáu (thời gian làm việc từ 7:30 sáng tới
                        5:00 chiều), Thứ Bảy (thời gian làm việc từ 7:30 sáng
                        tới 11:30 trưa), hoặc thời gian làm việc thực tế của PIF
                        theo từng thời kỳ; ngoại trừ các ngày nghỉ, lễ theo quy
                        định pháp luật hoặc các ngày nghỉ của PIF.
                    </p>
                </li>
            </ol>
        </ol>
        <p style="text-indent: 0pt; text-align: left"><br /></p>
        <h2 style="padding-left: 28pt; text-indent: 0pt; text-align: left">
            ĐIỀU 2: QUYỀN VÀ TRÁCH NHIỆM CỦA KHÁCH HÀNG
        </h2>
        <ol id="l3">
            <ol id="l4">
                <li data-list-text="2.1.">
                    <p
                        style="
                            padding-top: 9pt;
                            padding-left: 29pt;
                            text-indent: -19pt;
                            text-align: left;
                        "
                    >
                        Quyền của khách hàng
                    </p>
                    <ul id="l5">
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 8pt;
                                    padding-left: 64pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Được quyền đăng ký sử dụng dịch vụ tư vấn đầu tư
                                và các dịch vụ thuộc kênh, đi kèm kênh để thực
                                hiện các yêu cầu mà Khách hàng đã đăng ký.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 64pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Có quyền khiếu nại về các sai sót, vi phạm (nếu
                                có) phát sinh trong quá trình sử dụng dịch vụ
                                theo quy định của pháp luật. Khiếu nại phải được
                                lập thành văn bản và gửi cho PIF. Khách hàng
                                được quyền khiếu nại trong một khoảng thời gian
                                nhất định theo quy định của PIF phù hợp với quy
                                định pháp luật kể từ ngày phát sinh giao dịch đề
                                nghị tra soát, khiếu nại. Trường hợp khiếu nại
                                của Khách hàng không liên quan đến lỗi của PIF,
                                Khách hàng sẽ phải chịu chi phí phát sinh từ
                                việc xử lý khiếu nại theo quy định của PIF.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 4pt;
                                    padding-left: 64pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Yêu cầu PIF cập nhật kịp thời các thông tin đăng
                                ký sử dụng, thay đổi thông tin Khách hàng, người
                                đại diện theo pháp luật,
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 64pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Khách hàng được quyền tiếp nhận thông tin, yêu
                                cầu cung cấp thêm thông tin về dự án thực địa để
                                có thể kiểm chứng, xác thực và tự chịu trách
                                nhiệm cho quyết định đầu tư của bản thân.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 64pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Các quyền khác theo quy định của Điều khoản và
                                Điều kiện này và các quy định liên quan đến pháp
                                luật
                            </p>
                        </li>
                    </ul>
                </li>
                <li data-list-text="2.2.">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 29pt;
                            text-indent: -19pt;
                            text-align: left;
                        "
                    >
                        Trách nhiệm của Khách hàng
                    </p>
                    <ul id="l6">
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 8pt;
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Cung cấp đầy đủ, chính xác các thông tin cần
                                thiết theo yêu cầu của PIF khi đăng ký sử dụng
                                dịch vụ tư vấn đầu tư.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                class="s1"
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                <span class="p"
                                    >Đồng ý cho PIF trích tiền Tài khoản đầu tư
                                    để thanh toán các loại phí liên quan đến
                                    dịch vụ, các loại phí liên quan đến quản lý
                                    tài khoản theo quy định của PIF.</span
                                >
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Thanh toán đầy đủ các khoản phí liên quan đến
                                dịch vụ tư vấn đầu tư và các dịch vụ thuộc kênh,
                                đi kèm kênh theo biểu phí được PIF quy định theo
                                từng thời kỳ.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Không đăng ký sử dụng dịch vụ tư vấn đầu tư khi
                                Tài khoản đầu tư đã tất toán, bị phong tỏa, tạm
                                khóa, tạm ngưng giao dịch dưới mọi hình thức.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Thông báo ngay cho PIF những thay đổi về địa chỉ
                                thường trú, địa chỉ liên lạc, số điện
                                thoại/email đăng ký nhắc lịch hoặc các thông tin
                                khác đã đăng ký với PIF. Trường hợp Khách hàng
                                không thông báo hoặc chưa thông báo hợp lệ thì
                                Khách hàng phải chịu hoàn toàn trách nhiệm đối
                                với mọi thiệt hại, trách nhiệm đối với bên thứ
                                ba (nếu có) do việc sử dụng tài khoản, dịch vụ
                                eBanking, không liên quan PIF, và không ảnh
                                hưởng đến giá trị hiệu lực các giao dịch đã thực
                                hiện. Trường hợp Khách hàng đã thông báo hợp lệ,
                                PIF sẽ cập nhật thông tin điều chỉnh cho Khách
                                hàng trong vòng 01 ngày làm việc kể từ thời điểm
                                tiếp nhận thông báo từ Khách hàng. Thông báo hợp
                                lệ là thông báo phải do chính Khách hàng lập
                                theo mẫu do PIF ban hành cùng với hồ sơ chứng
                                minh nội dung thông tin thay đổi và được Khách
                                hàng gửi đến cho PIF bằng các phương thức thông
                                báo phù hợp (văn bản, điện tử…).
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Hợp tác và cung cấp thông tin cần thiết theo yêu
                                cầu của PIF phù hợp với thỏa thuận giữa Khách
                                hàng với PIF và quy định pháp luật.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Hoàn trả cho PIF những khoản thanh toán thừa,
                                thanh toán quá số dư được phép trên tài khoản và
                                lãi phát sinh theo quy định của PIF, những khoản
                                tiền ghi có nhầm, thừa vào tài khoản (nếu có).
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Cam kết không đăng ký sử dụng dịch vụ tư vấn đầu
                                tư nhằm mục đích thực hiện các giao dịch trái
                                với pháp luật.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    text-align: left;
                                "
                            >
                                Các trách nhiệm khác theo quy định của PIF và
                                các quy định pháp luật có liên quan.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Đảm bảo tài khoản đầu tư đủ số dư khả dụng hoặc
                                thực hiện chuyển tiền đúng hạn mức tại thời điểm
                                phát sinh thanh toán và thời gian gia hạn thanh
                                toán (bao gồm số tiền theo lệnh thanh toán và số
                                tiền phí dịch vụ (nếu có) để PIF thực thi lệnh
                                đầu tư). Nếu số dư không đảm bảo, PIF có quyền
                                (nhưng không có nghĩa vụ) được tạm ngưng cung
                                cấp dịch vụ nhắc lịch thanh toán và/hoặc tạm
                                ngưng hiệu lực Tư vấn đầu tư và khi có nhu cầu
                                tiếp tục sử dụng dịch vụ, Khách hàng thực hiện
                                thông báo theo đúng thủ tục của PIF quy định
                                trong từng thời kỳ.
                            </p>
                        </li>
                    </ul>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 7pt;
                padding-left: 28pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 3: QUYỀN VÀ TRÁCH NHIỆM CỦA PIF
        </h2>
        <ol id="l7">
            <ol id="l8">
                <li data-list-text="3.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 26pt;
                            text-indent: -16pt;
                            text-align: left;
                        "
                    >
                        Quyền của PIF
                    </p>
                    <ul id="l9">
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 9pt;
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    text-align: left;
                                "
                            >
                                Sử dụng thông tin tra soát về tài khoản của
                                Khách hàng trong các trường hợp sau:
                            </p>
                            <ul id="l10">
                                <li data-list-text="">
                                    <p
                                        style="
                                            padding-top: 4pt;
                                            padding-left: 84pt;
                                            text-indent: -18pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        Cung cấp cho nhân viên của PIF và/hoặc
                                        bên thứ ba khi các nhân viên này và/hoặc
                                        bên thứ ba cần phải biết thông tin để
                                        (i) thực thi các yêu cầu của khách hàng;
                                        (ii) cung cấp các sản phẩm, dịch vụ và
                                        xử lý các giao dịch; (iii) khuyến mại,
                                        cung cấp ưu đãi đặc quyền, cải thiện các
                                        sản phẩm, dịch vụ của PIF hoặc đối tác;
                                        (iv) sử dụng làm bằng chứng trong quá
                                        trình giải quyết tranh chấp giữa PIF,
                                        Khách hàng và các bên liên quan;
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p
                                        style="
                                            padding-left: 84pt;
                                            text-indent: -18pt;
                                            line-height: 108%;
                                            text-align: justify;
                                        "
                                    >
                                        Cung cấp thông tin theo yêu cầu bằng văn
                                        bản của Khách hàng và/hoặc Người Giám
                                        hộ/Người đại diện theo pháp luật của
                                        Khách hàng hoặc Bên thứ ba được Khách
                                        hàng đồng ý hoặc ủy quyền;
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p
                                        style="
                                            padding-left: 84pt;
                                            text-indent: -18pt;
                                            line-height: 108%;
                                            text-align: justify;
                                        "
                                    >
                                        Cung cấp thông tin theo yêu cầu bằng văn
                                        bản của cơ quan nhà nước có thẩm quyền
                                        hoặc theo quy định của pháp luật;
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p
                                        style="
                                            padding-left: 84pt;
                                            text-indent: -18pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        Cung cấp thông tin khi PIF cho rằng việc
                                        tiết lộ thông tin là cần thiết hoặc
                                        thích hợp để ngăn chặn, phát hiện giao
                                        dịch bất hợp pháp hoặc nghi ngờ là bất
                                        hợp pháp, phòng chống rửa tiền, chống
                                        tài trợ khủng bố.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Từ chối thực hiện các giao dịch không hợp pháp,
                                hợp lệ theo PIF hoặc theo quy định của pháp luật
                                hoặc trong các trường hợp ngoài khả năng kiểm
                                soát của PIF.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Được quyền miễn trừ trách nhiệm về bất kỳ thiệt
                                hại nào có thể phát sinh bởi việc không thể hủy
                                bỏ, sửa đổi, chậm thực hiện các lệnh giao dịch
                                của Khách hàng khi các lệnh này đã được thực
                                thi.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Được quyền miễn trừ trách nhiệm về bất kỳ thiệt
                                hại nào phát sinh bao gồm cả sự khác biệt giữa
                                Ngày giao dịch và Ngày hiệu lực, ngày nghỉ lễ
                                của PIF hoặc quy định luật, giữa thời gian làm
                                việc trong ngày, giữa thời gian ghi nhận giữa
                                các hệ thống, các chương trình ứng dụng liên
                                quan hay sự cố hệ thống.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: left;
                                "
                            >
                                Được quyền tạm ngừng/chấm dứt việc sử dụng dịch
                                vụ của Khách hàng mà không cần thông báo trước
                                trong các trường hợp sau:
                            </p>
                            <ol id="l11">
                                <li data-list-text="i.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -23pt;
                                            text-align: left;
                                        "
                                    >
                                        PIF nghi ngờ và/hoặc nhận được thông báo
                                        từ bên thứ ba về:
                                    </p>
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: 2pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        + Giao dịch có dấu hiệu vi phạm pháp
                                        luật hay quy định nội bộ của PIF, bao
                                        gồm các quy định pháp luật liên quan đến
                                        quản lý, phòng chống rửa tiền, hiệp
                                        định, hiệp ước mà Việt Nam có tham gia
                                        ký kết và các thông lệ quốc tế liên
                                        quan.
                                    </p>
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: 0pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        + Giao dịch có liên quan đến bất kỳ bên
                                        nào (cá nhân, tổ chức, cơ quan chính
                                        phủ) bị xử phạt vi phạm pháp luật
                                        và/hoặc có liên quan trực tiếp hay gián
                                        tiếp đến đối tượng bị xử phạt vi phạm
                                        pháp luật bởi các Liên hiệp, tổ chức
                                        quốc tế hay luật quốc gia liên quan.
                                    </p>
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: 0pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        + Giao dịch có thể trực tiếp hoặc gián
                                        tiếp liên quan đến nguồn thu hay được sử
                                        dụng vì mục đích hoặc cho hành vi bất
                                        hợp pháp.
                                    </p>
                                </li>
                                <li data-list-text="ii.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -26pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        PIF yêu cầu Khách hàng cung cấp thêm
                                        thông tin và/hoặc các tài liệu chứng
                                        minh giao dịch hợp pháp trước khi thực
                                        hiện Lệnh giao dịch hoặc Khách hàng từ
                                        chối cung cấp thêm thông tin và/hoặc các
                                        tài liệu theo yêu cầu của PIF;
                                    </p>
                                </li>
                                <li data-list-text="iii.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -29pt;
                                            text-align: left;
                                        "
                                    >
                                        Theo quyết định, yêu cầu của pháp luật
                                        hoặc cơ quan nhà nước có thẩm quyền;
                                    </p>
                                </li>
                                <li data-list-text="iv.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -29pt;
                                            text-align: left;
                                        "
                                    >
                                        Các trường hợp liên quan đến giả mạo và
                                        rủi ro;
                                    </p>
                                </li>
                                <li data-list-text="v.">
                                    <p
                                        style="
                                            padding-top: 1pt;
                                            padding-left: 82pt;
                                            text-indent: -26pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        Khi lợi ích của Khách hàng/PIF/bên thứ
                                        ba có thể bị tổn hại hoặc giao dịch có
                                        dấu hiệu gian lận;
                                    </p>
                                </li>
                                <li data-list-text="vi.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -29pt;
                                            text-align: left;
                                        "
                                    >
                                        PIF tạm ngưng dịch vụ để bảo trì;
                                    </p>
                                </li>
                                <li data-list-text="vii.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -32pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        Khi phát hiện Khách hàng vi phạm bất kỳ
                                        nội dung nào của Điều khoản và điều kiện
                                        này hoặc bất kỳ cam kết, thỏa thuận hay
                                        hợp đồng nào đã cam kết, ký với PIF cho
                                        dù cam kết, thỏa thuận, hợp đồng đó có
                                        liên quan hay không liên quan đến việc
                                        sử dụng dịch vụ tư vấn đầu tư;
                                    </p>
                                </li>
                                <li data-list-text="viii.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -35pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        Khi giao dịch vượt quá số dư được phép
                                        sử dụng trên tài khoản không đủ để thanh
                                        toán giá trị giao dịch, tiền phạt và
                                        phí.
                                    </p>
                                </li>
                                <li data-list-text="ix.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -29pt;
                                            text-align: left;
                                        "
                                    >
                                        Các trường hợp bất khả kháng ngoài khả
                                        năng kiểm soát của PIF.
                                    </p>
                                </li>
                                <li data-list-text="x.">
                                    <p
                                        style="
                                            padding-top: 3pt;
                                            padding-left: 82pt;
                                            text-indent: -26pt;
                                            line-height: 108%;
                                            text-align: left;
                                        "
                                    >
                                        Không phát sinh dư nợ cần thanh toán tại
                                        thời điểm thực hiện thanh toán theo thỏa
                                        thuận trong Hợp đồng.
                                    </p>
                                </li>
                                <li data-list-text="xi.">
                                    <p
                                        style="
                                            padding-left: 82pt;
                                            text-indent: -29pt;
                                            line-height: 107%;
                                            text-align: left;
                                        "
                                    >
                                        Thông tin tài khoản chỉ định thanh toán
                                        được đăng ký là tài khoản ghi nợ hoặc
                                        nhận báo có đã tất toán hoặc không còn
                                        tồn tại trên hệ thống.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Ghi nợ tài khoản giá trị tất cả các giao dịch,
                                các khoản phí liên quan đến dịch vụ theo Biểu
                                phí được PIF quy định theo từng thời kỳ, các
                                khoản giao dịch thừa, rút quá số dư các khoản
                                tiền ghi có, ghi nhầm vào tài khoản (nếu có).
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: justify;
                                "
                            >
                                Phong tỏa và ghi nợ số tiền trên bất kỳ tài
                                khoản nào của Khách hàng để thu các loại phí
                                phát sinh từ việc sử dụng Dịch vụ, các khoản
                                tiền mà Khách hàng có nghĩa vụ hoàn trả theo
                                thỏa thuận giữa PIF và Khách hàng, theo quy định
                                pháp luật hoặc yêu cầu của cơ quan có thẩm
                                quyền.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: justify;
                                "
                            >
                                Ghi nợ số tiền tranh chấp và các khoản phí phát
                                sinh khi tranh chấp được đưa ra các cơ quan tài
                                phán (tòa án, trọng tài) và các cơ quan này xử
                                Khách hàng thua kiện.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 107%;
                                    text-align: justify;
                                "
                            >
                                Các quyền khác theo quy định Điều khoản và Điều
                                kiện này, các quy định của pháp luật có liên
                                quan.
                            </p>
                        </li>
                    </ul>
                </li>
                <li data-list-text="3.2">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 44pt;
                            text-indent: -16pt;
                            text-align: left;
                        "
                    >
                        Trách nhiệm của PIF
                    </p>
                    <ul id="l12">
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 8pt;
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    text-align: left;
                                "
                            >
                                Đảm bảo các quyền lợi của Khách hàng trong Điều
                                khoản và điều kiện.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Bảo mật các thông tin liên quan đến Khách hàng,
                                tài khoản và giao dịch theo quy định của pháp
                                luật.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 13pt;
                                    text-align: left;
                                "
                            >
                                Giải quyết các yêu cầu tra soát và khiếu nại của
                                Khách hàng liên quan đến việc sử dụng dịch vụ.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 1pt;
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                PIF sẽ nỗ lực để các thông tin cung cấp cho
                                Khách hàng qua dịch vụ là chính xác theo hệ
                                thống máy tính ghi nhận. Tuy nhiên do đặc thù
                                của sản phẩm và hoàn cảnh vượt quá phạm vi kiểm
                                soát của PIF, PIF không đảm bảo, trong mọi
                                trường hợp mọi thông tin là chính xác và không
                                có gì sai sót.
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                PIF không có trách nhiệm với những thiệt hại
                                trực tiếp hoặc gián tiếp mà Khách hàng phải chịu
                                từ hoặc do các nguyên nhân sau:
                            </p>
                        </li>
                    </ul>
                </li>
            </ol>
        </ol>
        <ol id="l13">
            <li data-list-text="i.">
                <p
                    style="
                        padding-left: 84pt;
                        text-indent: -23pt;
                        line-height: 108%;
                        text-align: left;
                    "
                >
                    Khách hàng sử dụng hình thức thanh toán khác mà không yêu
                    cầu tạm ngừng, chấm dứt dịch vụ tư vấn đầu tư và PIF tiếp
                    tục thực hiện thanh toán các giao dịch đã được ký kết bằng
                    Phiếu đăng ký sử dụng dịch vụ tư vấn đầu tư giữa Khách hàng
                    và PIF trong thời gian Lịch thanh toán và gia hạn thanh toán
                    còn hiệu lực.
                </p>
            </li>
            <li data-list-text="ii.">
                <p
                    style="
                        padding-left: 84pt;
                        text-indent: -26pt;
                        line-height: 108%;
                        text-align: left;
                    "
                >
                    Nguyên nhân vượt quá phạm vi kiểm soát của PIF dẫn đến không
                    thể thực hiện được các yêu cầu ủy thác của bên đăng ký bao
                    gồm: sự ngắt quãng, trì hoãn, chậm trễ, tình trạng không sẵn
                    sàng sử dụng hoặc bất kỳ sự cố nào (đứt nghẽn mạch, mất
                    điện…) xảy ra trong quá trình cung cấp dịch vụ do các nguyên
                    nhân ngoài khả năng kiểm soát hợp lý của PIF, kể cả tình
                    trạng gián đoạn do dịch vụ cần được nâng cấp, sửa chữa; lỗi
                    đường truyền của nhà cung cấp dịch vụ Internet; lỗi gián
                    đoạn do nhà cung cấp dịch vụ và/hoặc đối tác liên kết; hoặc
                </p>
            </li>
            <li data-list-text="iii.">
                <p
                    style="
                        padding-left: 84pt;
                        text-indent: -29pt;
                        line-height: 107%;
                        text-align: left;
                    "
                >
                    Các trường hợp bất khả kháng (các sự kiện xảy ra một cách
                    khách quan, không thể lường trước được và không thể khắc
                    phục được như thiên tai, đình công, khủng bố, chiến
                </p>
                <p
                    style="
                        padding-left: 84pt;
                        text-indent: 0pt;
                        line-height: 108%;
                        text-align: left;
                    "
                >
                    tranh… mặc dù đã áp dụng mọi thủ tục cần thiết và khả năng
                    cho phép) theo quy định pháp luật hoặc theo yêu cầu, chỉ thị
                    của các cơ quan Nhà nước có thẩm quyền hoặc trở ngại khách
                    quan (là những trở ngại do hoàn cảnh khách quan tác động làm
                    cho PIF không thể biết về việc quyền, lợi ích hợp pháp của
                    mình bị xâm phạm hoặc không thể thực hiện được quyền hoặc
                    nghĩa vụ dân sự của mình).
                </p>
            </li>
            <li data-list-text="iv.">
                <p
                    style="
                        padding-left: 84pt;
                        text-indent: -29pt;
                        line-height: 108%;
                        text-align: left;
                    "
                >
                    Các trách nhiệm khác theo quy định tại Điều khoản và Điều
                    kiện này và các quy định pháp luật có liên quan.
                </p>
            </li>
        </ol>
        <h2
            style="
                padding-top: 3pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 4: TƯ VẤN ĐẦU TƯ THEO LỊCH
        </h2>
        <ol id="l14">
            <ol id="l15">
                <li data-list-text="4.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Thanh toán theo chu kỳ: là lệnh thanh toán có tính chất
                        lặp đi lặp lại theo chu kỳ (tuần/tháng/ năm) cho đến khi
                        hết hạn hiệu lực của Phụ lục đầu tư hoặc khi Khách hàng
                        yêu cầu tạm ngưng dịch vụ.
                    </p>
                </li>
                <li data-list-text="4.2">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 107%;
                            text-align: left;
                        "
                    >
                        Thanh toán theo ngày cụ thể: Khách hàng đăng ký ngày
                        thanh toán cụ thể và không có tính chất lặp theo chu kỳ
                        lệnh thanh toán đó.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 8pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 5: NHẮC LỊCH THANH TOÁN
        </h2>
        <ol id="l16">
            <ol id="l17">
                <li data-list-text="5.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Thời gian nhắc lịch được thực hiện đúng theo quyền lợi
                        Khách hàng đã cập nhật trên Phụ lục đầu tư kể cả ngày
                        nghỉ, lễ.
                    </p>
                </li>
                <li data-list-text="5.2">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 26pt;
                            text-indent: -16pt;
                            text-align: left;
                        "
                    >
                        Nhắc lịch thanh toán sẽ tự động hết hiệu lực khi Phụ lục
                        đầu tư hết hiệu lực.
                    </p>
                </li>
                <li data-list-text="5.3">
                    <p
                        style="
                            padding-top: 9pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Các thông tin số điện thoại, địa chỉ email được đăng ký
                        tại Phiếu đăng ký có thể là số điện thoại, địa chỉ email
                        Khách hàng đăng ký nhận thông tin liên hệ hoặc nhận
                        thông báo mới từ PIF hoặc số điện thoại, địa chỉ email
                        khác do Khách hàng chỉ định.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 8pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 6: THỜI GIAN XỬ LÝ VÀ HIỆU LỰC CỦA GIAO DỊCH
        </h2>
        <ol id="l18">
            <ol id="l19">
                <li data-list-text="6.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Thời gian xử lý của một giao dịch hợp lệ được tính từ
                        thời điểm Phiếu đăng ký của Khách hàng có hiệu lực và
                        PIF ghi nhận được giao dịch đó. Các yêu cầu giao dịch
                        được gửi từ phía Khách hàng không có nghĩa là các giao
                        dịch đã được PIF ghi nhận và xử lý. PIF chỉ tiến hành
                        ghi nợ/ghi có theo quy định Tài khoản và theo quy định
                        tại Điều khoản và điều kiện này sau khi PIF đã kiểm tra
                        số dư khả dụng hoặc hạn mức còn lại mà hệ thống đã ghi
                        nhận. Các lệnh thanh toán của Khách hàng được ghi Nợ
                        không đồng nghĩa với việc người thụ hưởng được ghi Có và
                        hóa đơn được gạch nợ. Việc ghi Có trên TK của người thụ
                        hưởng sẽ chỉ được thực hiện sau khi PIF và ngân hàng
                        phục vụ người thụ hưởng kiểm tra chi tiết và chấp nhận
                        lệnh chuyển tiền và việc gạch nợ sẽ được thực hiện bởi
                        các nhà cung cấp dịch vụ/đối tác liên kết.
                    </p>
                </li>
                <li data-list-text="6.2">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Các lệnh giao dịch được PIF thực thi trong các ngày làm
                        việc của mình. Thời gian xử lý giao dịch theo Lịch thanh
                        toán mà khách hàng đã đăng ký chỉ mang tính tương đối.
                        PIF sẽ nỗ lực đáp ứng chính xác thời gian xử lý, trừ
                        trường hợp lệnh không thực hiện/thực hiện chậm, sự khác
                        biệt giữa ngày giao dịch và ngày hiệu lực do nguyên nhân
                        khách quan, vượt ngoài tầm kiểm soát của PIF; hoặc
                        trường hợp ngày giao dịch rơi vào ngày nghỉ lễ của PIF
                        hoặc quy định luật, thời điểm phát sinh giao dịch sau
                        thời gian xử lý giao dịch cuối ngày, hoặc đã hết thời
                        gian làm việc của các hệ thống, các chương trình ứng
                        dụng liên quan.
                    </p>
                </li>
                <li data-list-text="6.3">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Trường hợp lệnh giao dịch cần đến bên thứ ba để xử lý
                        hoàn tất (thanh toán ngoài hệ thống PIF): Các lệnh giao
                        dịch được PIF thực thi trong các ngày làm việc của mình
                        và của bên thứ ba liên quan. Cụ thể như sau:
                    </p>
                </li>
                <li data-list-text="6.4">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Lệnh giao dịch đủ điều kiện thanh toán được PIF ghi nhận
                        trước 9h00 sáng (buổi sáng), trước 15h00 (buổi chiều) từ
                        thứ 2 đến thứ 6: Thực thi ngay trong ngày làm việc.
                    </p>
                </li>
                <li data-list-text="6.5">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 107%;
                            text-align: left;
                        "
                    >
                        Lệnh giao dịch đủ điều kiện thanh toán được PIF ghi nhận
                        sau thời gian trên: Thực thi vào đầu ngày làm việc kế
                        tiếp của PIF và bên thứ ba có liên quan.
                    </p>
                </li>
                <li data-list-text="6.6">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Lệnh giao dịch đủ điều kiện thanh toán là lệnh giao dịch
                        mà tài khoản thanh toán của Khách hàng phải đảm bảo đủ
                        số dư khả dụng hoặc tài khoản đầu tư (phần quản lý đầu
                        tư) phải có đủ hạn mức tại thời điểm thanh toán.
                    </p>
                </li>
                <li data-list-text="6.7">
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Khi Lịch thanh toán của Khách hàng đến kỳ thực hiện và
                        tài khoản Khách hàng đảm bảo đủ số dư hoặc hạn mức, PIF
                        sẽ cố gắng thực thi ngay, tuy nhiên tùy theo tình hình
                        thực tế thời điểm thực thi các lệnh giao dịch này có thể
                        không tuân theo các quy định trên.
                    </p>
                </li>
                <li data-list-text="6.8">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Thời hạn giải quyết khiếu nại của Khách hàng: Là thời
                        hạn PIF giải quyết và/hoặc phản hồi cho khách hàng kể từ
                        sau khi tiếp nhận được khiếu nại của Khách hàng. Thời
                        hạn giải quyết khiếu nại thực hiện theo các quy định
                        hiện hành của PIF tương ứng với từng loại sản phẩm, dịch
                        vụ, thỏa thuận với các Nhà cung cấp dịch vụ/Đơn vị chấp
                        nhận thanh toán, Đối tác cung cấp dịch vụ trung gian
                        thanh toán phù hợp với quy định pháp luật. PIF sẽ hoàn
                        trả cho Khách hàng giá trị các giao dịch khiếu nại đúng.
                        Trường hợp Khách hàng khiếu nại sai Khách hàng sẽ chịu
                        các phí liên quan theo quy định hiện hành của PIF. Các
                        trường hợp đặc biệt khác, PIF sẽ thông báo cụ thể cho
                        Khách hàng.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 7pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 7: GIAO DỊCH KHÔNG HỦY NGANG
        </h2>
        <ol id="l20">
            <ol id="l21">
                <li data-list-text="7.1">
                    <p
                        style="
                            padding-top: 9pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Khách hàng không thể hủy, phủ nhận, thoái thác bất kỳ
                        giao dịch mà Khách hàng đã đăng ký ủy thác cho PIF thanh
                        toán theo Phiếu đăng ký. Trường hợp Khách hàng muốn yêu
                        cầu hủy giao dịch của mình, PIF chỉ xem xét với điều
                        kiện (i) yêu cầu hủy giao dịch được gửi đến PIF trước
                        ngày lệnh thanh toán được PIF thực thi, và (ii) hủy giao
                        dịch không ảnh hưởng đến lợi ích của PIF cũng như lợi
                        ích của bất kỳ bên thứ ba nào khác.
                    </p>
                </li>
                <li data-list-text="7.2">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 107%;
                            text-align: left;
                        "
                    >
                        Bất kỳ Giao dịch nào đã được thực hiện theo yêu cầu mà
                        Khách hàng đã đăng ký trên Phiếu đăng ký sẽ được PIF coi
                        là có giá trị và không hủy ngang.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 8pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 8: CHỨNG TỪ
        </h2>
        <ol id="l22">
            <ol id="l23">
                <li data-list-text="8.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 26pt;
                            text-indent: -16pt;
                            text-align: left;
                        "
                    >
                        Các tài liệu, chứng từ liên quan đến việc cung cấp Dịch
                        vụ tư vấn đầu tư giữa PIF và Khách hàng
                    </p>
                    <p
                        style="
                            padding-top: 1pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        cũng như các số liệu được ghi chép, xác nhận và lưu giữ
                        bởi PIF sẽ là bằng chứng về việc giao dịch của Khách
                        hàng và PIF, các bằng chứng này có đầy đủ tính pháp lý
                        của một Hợp đồng đã được PIF và Khách hàng thỏa thuận
                        xác nhận.
                    </p>
                </li>
                <li data-list-text="8.2">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Thông tin các báo cáo tài chính thể hiện doanh thu, lợi
                        nhuận hàng ngày của dự án thực địa sẽ được PIF phát hành
                        theo phương thức điện tử. Khách hàng chủ động tra cứu,
                        xem tại danh mục quản lý đầu tư trên tài khoản của mình
                        hoặc đến Điểm giao dịch PIF khi có nhu cầu tra cứu, xác
                        thực số liệu tài chính thực tế.
                    </p>
                </li>
                <li data-list-text="8.3">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Về hóa đơn hoặc ghi nhận các giao dịch phát sinh liên
                        quan đến việc huy động vốn từ địa điểm thực địa, sẽ được
                        Ngân hàng phát hành sao kê và được PIF trích lục, cập
                        nhật lên thông tin từng dự án thực địa để đảm bảo sự
                        minh bạch khi sử dụng vốn hợp tác đầu tư.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 8pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 9: THỎA THUẬN VỀ PHÍ DỊCH VỤ, BIỂU PHÍ, GIỚI HẠN GIAO DỊCH
        </h2>
        <ol id="l24">
            <ol id="l25">
                <li data-list-text="9.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Biểu phí (bao gồm mức phí, phương thức thu phí) sử dụng
                        dịch vụ tư vấn đầu tư và các dịch vụ liên quan sẽ do PIF
                        quy định theo từng thời kỳ. Khi có sự thay đổi về loại
                        phí, mức phí, PIF sẽ thông báo cho khách hàng qua
                        Website PIF hay các hình thức khác (niêm yết tại địa
                        điểm giao dịch, gửi thư (thư điện tử), điện thoại của
                        Khách hàng…) phù hợp với quy định pháp luật. Biểu phí sẽ
                        có giá trị ràng buộc giữa hai bên nếu Khách hàng tiếp
                        tục sử dụng dịch vụ tư vấn đầu tư và các dịch vụ liên
                        quan sau khi biểu phí thay đổi có hiệu lực.
                    </p>
                </li>
                <li data-list-text="9.2">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Khách hàng cam kết đồng ý thanh toán phí cho việc sử
                        dụng dịch vụ tư vấn đầu tư và các sản phẩm liên quan
                        được PIF cung cấp được tính từ thời điểm Phiếu đăng ký
                        có hiệu lực.
                    </p>
                </li>
                <li data-list-text="9.3">
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        PIF sẽ ngưng tính phí sử dụng dịch vụ tư vấn đầu tư và
                        các sản phẩm liên quan kể từ thời điểm mà việc đăng ký
                        ngưng sử dụng các dịch vụ này có hiệu lực (đối với các
                        dịch vụ có tính phí).
                    </p>
                </li>
                <li data-list-text="9.4">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Các khoản phí liên quan đến sử dụng dịch vụ tư vấn đầu
                        tư và các dịch vụ liên quan đã được thu sẽ không được
                        hoàn lại trong mọi trường hợp.
                    </p>
                </li>
                <li data-list-text="9.5">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: justify;
                        "
                    >
                        Khách hàng cam kết rằng trong trường hợp tài khoản được
                        chỉ định dùng để thanh toán phí của Khách hàng không đủ
                        số dư hoặc hạn mức để trả phí, PIF có quyền trích thu
                        phí từ các tài khoản khác còn lại của Khách hàng mở tại
                        PIF.
                    </p>
                </li>
                <li data-list-text="9.6">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: justify;
                        "
                    >
                        Trong trường hợp thu phí theo tỉ lệ phần trăm trên giá
                        trị giao dịch thì mức thu phí thực tế không thấp hơn mức
                        phí tối thiểu và không cao hơn mức phí tối đa do PIF quy
                        định tại Biểu phí dịch vụ.
                    </p>
                </li>
                <li data-list-text="9.7">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Khách hàng cam kết thanh toán phí phát sinh mỗi tháng
                        cho PIF trong vòng tối đa 28 ngày kể từ ngày 1 của tháng
                        kế tiếp sau đó và đảm bảo tài khoản được chỉ định dùng
                        để thanh toán phí của Khách hàng đủ số dư hoặc hạn mức
                        để PIF tự động trích phí trong thời hạn này. Nếu quá
                        thời hạn trên mà chưa thanh toán, PIF được quyền ngừng
                        cung cấp các dịch vụ tư vấn đầu tư và được quyền truy
                        thu các khoản phí
                    </p>
                    <p
                        style="
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 13pt;
                            text-align: left;
                        "
                    >
                        chưa được thanh toán trước đó.
                    </p>
                </li>
                <li data-list-text="9.8">
                    <p
                        style="
                            padding-top: 9pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        PIF sẽ chấm dứt việc sử dụng dịch vụ của Khách hàng kể
                        từ ngày nhận được thông báo về việc chấm dứt sử dụng
                        dịch vụ bằng văn bản của Khách hàng sau khi đã trừ đi
                        các khoản phí, chi phí liên quan đã phát sinh trong quá
                        trình sử dụng dịch vụ.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 7pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 10: SỬA ĐỔI NỘI DUNG ĐIỀU KHOẢN
        </h2>
        <ol id="l26">
            <ol id="l27">
                <li data-list-text="10.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        PIF được phép sửa đổi, bổ sung nội dung Điều khoản và
                        điều kiện này bằng cách thông báo trên Website PIF.
                    </p>
                </li>
                <li data-list-text="10.2">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Việc Khách hàng tiếp tục sử dụng dịch vụ sau thời điểm
                        có hiệu lực của sửa đổi Điều khoản và Điều kiện này có
                        nghĩa là Khách hàng chấp nhận hoàn toàn việc sửa đổi đó.
                        Trong trường hợp Khách hàng không đồng ý với những sửa
                        đổi, bổ sung thì không có quyền chấm dứt sử dụng dịch vụ
                        theo khoản 7.2 Điều 7 Điều khoản và điều kiện này.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 8pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 11: LUẬT ĐIỀU CHỈNH, GIẢI QUYẾT TRANH CHẤP
        </h2>
        <ol id="l28">
            <ol id="l29">
                <li data-list-text="11.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 32pt;
                            text-indent: -22pt;
                            text-align: left;
                        "
                    >
                        Các điều khoản và điều kiện sử dụng này được điều chỉnh
                        bởi pháp luật Việt Nam.
                    </p>
                </li>
                <li data-list-text="11.2">
                    <p
                        style="
                            padding-top: 9pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Nếu có bất kỳ tranh chấp nào phát sinh từ việc thực hiện
                        các Điều khoản và điều kiện sử dụng giữa Khách hàng và
                        PIF, thì tranh chấp đó trước hết sẽ được giải quyết qua
                        thương lượng, hòa giải. Trường hợp hòa giải không thành,
                        các Bên có quyền đưa tranh chấp ra Tòa án có thẩm quyền
                        ở Việt Nam để giải quyết.
                    </p>
                </li>
                <li data-list-text="11.3">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 107%;
                            text-align: left;
                        "
                    >
                        Trong trường hợp có sự mẫu thuẫn giữa bản tiếng Việt và
                        tiếng Anh thì bản tiếng Việt được ưu tiên áp dụng.
                    </p>
                </li>
            </ol>
        </ol>
        <h2
            style="
                padding-top: 8pt;
                padding-left: 26pt;
                text-indent: 0pt;
                text-align: left;
            "
        >
            ĐIỀU 12: THỜI HẠN HIỆU LỰC VÀ ĐIỀU KHOẢN THI HÀNH
        </h2>
        <ol id="l30">
            <ol id="l31">
                <li data-list-text="12.1">
                    <p
                        style="
                            padding-top: 8pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Các điều khoản và điều kiện sử dụng này có hiệu lực kể
                        từ ngày Khách hàng ký vào Phiếu đăng ký cho đến khi chấm
                        dứt trong các trường hợp sau:
                    </p>
                    <ul id="l32">
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 7pt;
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 108%;
                                    text-align: left;
                                "
                            >
                                Khách hàng đơn phương chấm dứt hoặc không tuân
                                thủ theo Khoản 2 Điều 7 Điều khoản và điều kiện
                                này;
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    line-height: 13pt;
                                    text-align: left;
                                "
                            >
                                Tài khoản chỉ định thanh toán bị đóng hoặc chỉ
                                định thanh toán bị chấm dứt sử dụng;
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 4pt;
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    text-align: left;
                                "
                            >
                                Khách hàng vi phạm bất kỳ điều khoản nào tại Hợp
                                đồng này hoặc thỏa thuận, cam kết với PIF;
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    text-align: left;
                                "
                            >
                                Chấm dứt theo yêu cầu của cơ quan nhà nước có
                                thẩm quyền;
                            </p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="
                                    padding-top: 1pt;
                                    padding-left: 46pt;
                                    text-indent: -18pt;
                                    text-align: left;
                                "
                            >
                                Các sự kiện pháp lý khác theo quy định pháp luật
                                dẫn đến chấm dứt Hợp đồng.
                            </p>
                        </li>
                    </ul>
                </li>
                <li data-list-text="12.2">
                    <p
                        style="
                            padding-top: 9pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Khách hàng xác nhận, đã đọc, hiểu rõ và đồng ý các Điều
                        khoản và điều kiện sử dụng dịch vụ tư vấn đầu tư, cam
                        kết thực hiện đúng các Điều khoản và điều kiện này cũng
                        như các thỏa thuận liên quan khác với PIF.
                    </p>
                </li>
                <li data-list-text="12.3">
                    <p
                        style="
                            padding-top: 7pt;
                            padding-left: 10pt;
                            text-indent: 0pt;
                            line-height: 108%;
                            text-align: left;
                        "
                    >
                        Phiếu đăng ký của Khách hàng có hiệu lực kể từ ngày do
                        Khách hàng chỉ định và được PIF xác nhận đồng ý cung cấp
                        dịch vụ, tuy nhiên sớm nhất là sau 72h kể từ khi Khách
                        hàng hoàn tất nghĩa vụ thanh toán, cung cấp được bằng
                        chứng chuyển tiền và xác nhận vào thư xác nhận đầu tư
                        được gửi vào email của Khách hàng, sau khi Khách hàng
                        hoàn tất nghĩa vụ thanh toán khoản đầu tư.
                    </p>
                </li>
            </ol>
        </ol>
    </b-container>
</template>

<style lang="scss">
.policy {
    padding: 50px 0;
    * {
        margin: 0;
        padding: 0;
        text-indent: 0;
    }
    h1 {
        color: black;

        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 18pt;
    }
    h2 {
        color: black;

        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 11pt;
    }
    .p,
    p {
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
        margin: 0pt;
    }
    .s1 {
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    li {
        display: block;
    }
    #l1 {
        padding-left: 0pt;
        counter-reset: c1 1;
    }
    #l1 > li > *:first-child:before {
        counter-increment: c1;
        content: counter(c1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l1 > li:first-child > *:first-child:before {
        counter-increment: c1 0;
    }
    #l2 {
        padding-left: 0pt;
        counter-reset: c2 1;
    }
    #l2 > li > *:first-child:before {
        counter-increment: c2;
        content: counter(c1, decimal) '.' counter(c2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l2 > li:first-child > *:first-child:before {
        counter-increment: c2 0;
    }
    li {
        display: block;
    }
    #l3 {
        padding-left: 0pt;
        counter-reset: d1 2;
    }
    #l3 > li > *:first-child:before {
        counter-increment: d1;
        content: counter(d1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l3 > li:first-child > *:first-child:before {
        counter-increment: d1 0;
    }
    #l4 {
        padding-left: 0pt;
        counter-reset: d2 1;
    }
    #l4 > li > *:first-child:before {
        counter-increment: d2;
        content: counter(d1, decimal) '.' counter(d2, decimal) '. ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l4 > li:first-child > *:first-child:before {
        counter-increment: d2 0;
    }
    #l5 {
        padding-left: 0pt;
    }
    #l5 > li > *:first-child:before {
        content: ' ';
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l6 {
        padding-left: 0pt;
    }
    #l6 > li > *:first-child:before {
        content: ' ';
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    li {
        display: block;
    }
    #l7 {
        padding-left: 0pt;
        counter-reset: e1 3;
    }
    #l7 > li > *:first-child:before {
        counter-increment: e1;
        content: counter(e1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l7 > li:first-child > *:first-child:before {
        counter-increment: e1 0;
    }
    #l8 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }
    #l8 > li > *:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal) '.' counter(e2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l8 > li:first-child > *:first-child:before {
        counter-increment: e2 0;
    }
    #l9 {
        padding-left: 0pt;
    }
    #l9 > li > *:first-child:before {
        content: ' ';
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l10 {
        padding-left: 0pt;
    }
    #l10 > li > *:first-child:before {
        content: ' ';
        color: black;
        font-family: Wingdings;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l11 {
        padding-left: 0pt;
        counter-reset: f1 1;
    }
    #l11 > li > *:first-child:before {
        counter-increment: f1;
        content: counter(f1, lower-roman) '. ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l11 > li:first-child > *:first-child:before {
        counter-increment: f1 0;
    }
    #l12 {
        padding-left: 0pt;
    }
    #l12 > li > *:first-child:before {
        content: ' ';
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    li {
        display: block;
    }
    #l13 {
        padding-left: 0pt;
        counter-reset: g1 1;
    }
    #l13 > li > *:first-child:before {
        counter-increment: g1;
        content: counter(g1, lower-roman) '. ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l13 > li:first-child > *:first-child:before {
        counter-increment: g1 0;
    }
    li {
        display: block;
    }
    #l14 {
        padding-left: 0pt;
        counter-reset: h1 4;
    }
    #l14 > li > *:first-child:before {
        counter-increment: h1;
        content: counter(h1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l14 > li:first-child > *:first-child:before {
        counter-increment: h1 0;
    }
    #l15 {
        padding-left: 0pt;
        counter-reset: h2 1;
    }
    #l15 > li > *:first-child:before {
        counter-increment: h2;
        content: counter(h1, decimal) '.' counter(h2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l15 > li:first-child > *:first-child:before {
        counter-increment: h2 0;
    }
    li {
        display: block;
    }
    #l16 {
        padding-left: 0pt;
        counter-reset: i1 5;
    }
    #l16 > li > *:first-child:before {
        counter-increment: i1;
        content: counter(i1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l16 > li:first-child > *:first-child:before {
        counter-increment: i1 0;
    }
    #l17 {
        padding-left: 0pt;
        counter-reset: i2 1;
    }
    #l17 > li > *:first-child:before {
        counter-increment: i2;
        content: counter(i1, decimal) '.' counter(i2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l17 > li:first-child > *:first-child:before {
        counter-increment: i2 0;
    }
    li {
        display: block;
    }
    #l18 {
        padding-left: 0pt;
        counter-reset: j1 6;
    }
    #l18 > li > *:first-child:before {
        counter-increment: j1;
        content: counter(j1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l18 > li:first-child > *:first-child:before {
        counter-increment: j1 0;
    }
    #l19 {
        padding-left: 0pt;
        counter-reset: j2 1;
    }
    #l19 > li > *:first-child:before {
        counter-increment: j2;
        content: counter(j1, decimal) '.' counter(j2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l19 > li:first-child > *:first-child:before {
        counter-increment: j2 0;
    }
    li {
        display: block;
    }
    #l20 {
        padding-left: 0pt;
        counter-reset: k1 7;
    }
    #l20 > li > *:first-child:before {
        counter-increment: k1;
        content: counter(k1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l20 > li:first-child > *:first-child:before {
        counter-increment: k1 0;
    }
    #l21 {
        padding-left: 0pt;
        counter-reset: k2 1;
    }
    #l21 > li > *:first-child:before {
        counter-increment: k2;
        content: counter(k1, decimal) '.' counter(k2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l21 > li:first-child > *:first-child:before {
        counter-increment: k2 0;
    }
    li {
        display: block;
    }
    #l22 {
        padding-left: 0pt;
        counter-reset: l1 8;
    }
    #l22 > li > *:first-child:before {
        counter-increment: l1;
        content: counter(l1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l22 > li:first-child > *:first-child:before {
        counter-increment: l1 0;
    }
    #l23 {
        padding-left: 0pt;
        counter-reset: l2 1;
    }
    #l23 > li > *:first-child:before {
        counter-increment: l2;
        content: counter(l1, decimal) '.' counter(l2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l23 > li:first-child > *:first-child:before {
        counter-increment: l2 0;
    }
    li {
        display: block;
    }
    #l24 {
        padding-left: 0pt;
        counter-reset: m1 9;
    }
    #l24 > li > *:first-child:before {
        counter-increment: m1;
        content: counter(m1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l24 > li:first-child > *:first-child:before {
        counter-increment: m1 0;
    }
    #l25 {
        padding-left: 0pt;
        counter-reset: m2 1;
    }
    #l25 > li > *:first-child:before {
        counter-increment: m2;
        content: counter(m1, decimal) '.' counter(m2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l25 > li:first-child > *:first-child:before {
        counter-increment: m2 0;
    }
    li {
        display: block;
    }
    #l26 {
        padding-left: 0pt;
        counter-reset: n1 10;
    }
    #l26 > li > *:first-child:before {
        counter-increment: n1;
        content: counter(n1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l26 > li:first-child > *:first-child:before {
        counter-increment: n1 0;
    }
    #l27 {
        padding-left: 0pt;
        counter-reset: n2 1;
    }
    #l27 > li > *:first-child:before {
        counter-increment: n2;
        content: counter(n1, decimal) '.' counter(n2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l27 > li:first-child > *:first-child:before {
        counter-increment: n2 0;
    }
    li {
        display: block;
    }
    #l28 {
        padding-left: 0pt;
        counter-reset: o1 11;
    }
    #l28 > li > *:first-child:before {
        counter-increment: o1;
        content: counter(o1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l28 > li:first-child > *:first-child:before {
        counter-increment: o1 0;
    }
    #l29 {
        padding-left: 0pt;
        counter-reset: o2 1;
    }
    #l29 > li > *:first-child:before {
        counter-increment: o2;
        content: counter(o1, decimal) '.' counter(o2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l29 > li:first-child > *:first-child:before {
        counter-increment: o2 0;
    }
    li {
        display: block;
    }
    #l30 {
        padding-left: 0pt;
        counter-reset: p1 12;
    }
    #l30 > li > *:first-child:before {
        counter-increment: p1;
        content: counter(p1, decimal) ' ';
        color: black;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }
    #l30 > li:first-child > *:first-child:before {
        counter-increment: p1 0;
    }
    #l31 {
        padding-left: 0pt;
        counter-reset: p2 1;
    }
    #l31 > li > *:first-child:before {
        counter-increment: p2;
        content: counter(p1, decimal) '.' counter(p2, decimal) ' ';
        color: black;

        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
    #l31 > li:first-child > *:first-child:before {
        counter-increment: p2 0;
    }
    #l32 {
        padding-left: 0pt;
    }
    #l32 > li > *:first-child:before {
        content: ' ';
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }
}
</style>
